import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode, faCartPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addToCart, setItemToEdit } from '../../store/actions/CartActions'
import { Offcanvas } from 'react-bootstrap'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { TOAST } from '../../utils/Helpers'
//import { updateCartItem } from '../../store/actions/CartActions'

const ProductInfo = ({ product, withSizes, name, scannedProduct, baseChoices, productBaseChoices, category, selectedProduct, _supplements, setSelectedProduct, setCategory, scaleWeight }) => {

  const itemToEdit = useSelector(state => state.cart.itemToEdit);
  const [defaultSupplements, setDefaultSupplements] = useState(_supplements?.filter(item => item.category === category._id));
  const [supplements, setSupplements] = useState(defaultSupplements);
  const [show, setShow] = useState(false);
  const [size, setSize] = useState(null);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [price, setPrice] = useState(0);
  const [selectedBaseChoices, setSelectedBaseChoices] = useState(baseChoices.map(item => ({ id: item._id, name: item.name, choice: item.choices[0].name, price: item.choices[0].price })))
  const [selectedProductBaseChoices, setSelectedProductBaseChoices] = useState(productBaseChoices.map(item => ({ id: item._id, name: item.name, choice: item.choices[0].name, price: item.choices[0].price })))
  const dispatch = useDispatch();
  const [selectedTab, selectTab] = useState("");

  const [baseChoicesSearchText, setBaseChoicesSearchText] = useState("");
  const [productBaseChoicesSearchText, setProductBaseChoicesSearchText] = useState("");
  const [supplementsSearchText, setSupplementsSearchText] = useState("");
  const [productWeight, setProductWeight] = useState("");
  const [productWeightPrice, setProductWeightPrice] = useState(0);
  const isDiversCategory = category?.name === "Divers"

  useEffect(() => {
    let supps = _supplements?.filter(item => item.category === category._id)
    setDefaultSupplements(_supplements?.filter(item => item.category === category._id))
  }, [category]);

  useEffect(() => {

    if (baseChoices?.length !== 0) {
      selectTab(baseChoices[0].name)
    } else if (productBaseChoices?.length !== 0) {
      selectTab(productBaseChoices[0].name)
    } else if (supplements?.length !== 0) {
      selectTab("Choix de suppléments")
    }

    if (itemToEdit?.product?._id === product._id) {
      selectProduct(product)
      setSize(itemToEdit.size)
      setPrice(itemToEdit.price)
      setSupplements(defaultSupplements.map((ingred => ({ ...ingred, quantity: getIngredientQuantity(ingred._id, itemToEdit.ingredients) }))))
      setSelectedSupplements(itemToEdit.ingredients.map((ingred => ({ ...ingred, quantity: getIngredientQuantity(ingred._id, itemToEdit.ingredients) }))))
      setSelectedBaseChoices(itemToEdit.baseChoices)
      setSelectedProductBaseChoices(itemToEdit.productBaseChoices)
    }
    setShow(itemToEdit?.product?._id === product._id);
  }, [itemToEdit]);

  useEffect(() => {
    setProductWeight(scaleWeight);
    setProductWeightPrice((product.pricePerKg * parseFloat(scaleWeight)).toFixed(1))
  }, [scaleWeight]);

  useEffect(() => {
    if (scannedProduct?._id === product?._id) {
      selectProduct(product)
    }
  }, [scannedProduct]);

  function getIngredientQuantity(idSupplement, updatedSupplements) {

    let _supplements = updatedSupplements.filter((ingredient) => ingredient._id === idSupplement)
    return _supplements[0]?.quantity || 1
  }

  function saveToCart() {

    let refCartItem = new Date().getTime()

    if (selectedProduct?.isProductWithWeight && (productWeightPrice === "NaN" || parseFloat(productWeightPrice) === 0)) {
      TOAST("Veuillez saisir le poids", "error")
      return
    }

    let item = {
      id: refCartItem,
      category: category,
      price: selectedProduct?.isProductWithWeight || isDiversCategory
        ? parseFloat(productWeightPrice)
        : (size ? price : selectedProduct.prices[0]?.price),
      size: selectedProduct?.isProductWithWeight ? { name: productWeight } : size,
      quantity: itemToEdit ? itemToEdit.quantity : 1,
      product: itemToEdit?.product || selectedProduct,
      ingredients: selectedSupplements,
      baseChoices: selectedBaseChoices,
      productBaseChoices: selectedProductBaseChoices,
      comment: itemToEdit?.comment || "",
      withSizes: selectedProduct?.isProductWithWeight || isDiversCategory ? false : withSizes,
      isMenuItem: false
    }

    dispatch(addToCart(item))
    setCategory(null)
    setShow(false)
    resetStates()
  }

  const handleModalClose = () => {
    setShow(false);
    resetStates()
    dispatch(setItemToEdit(null))
  };

  const setInfo = (size, price) => {
    setSize(size);
    setPrice(price);
  }

  const selectProduct = (item) => {
    if (item._id !== selectedProduct?._id) {
      setSelectedProduct(item);
    }
    setSelectedSupplements([]);
    setPrice(0);
    setShow(true);
  }

  const getSupplement = (ingredient) => {

    let price = !withSizes
      ? ingredient.prices[0]?.price
      : ingredient.prices.filter(price => price.size?.name === size?.name)[0]?.price
    return {
      name: ingredient.name,
      price: (price && ("€" + price)) || ""
    }
  }

  function addBaseChoice(idBaseChoice, choice) {

    var baseChoices = selectedBaseChoices.map(selectedBaseChoice => {
      if (selectedBaseChoice.id === idBaseChoice) {
        selectedBaseChoice.choice = choice.name
        selectedBaseChoice.price = choice.price
      }
      return selectedBaseChoice
    })
    setSelectedBaseChoices(baseChoices)
  }

  function addProductBaseChoice(idBaseChoice, choice) {
    var productBaseChoices = selectedProductBaseChoices.map(selectedProductBaseChoice => {
      if (selectedProductBaseChoice.id === idBaseChoice) {
        selectedProductBaseChoice.choice = choice.name
        selectedProductBaseChoice.price = choice.price
      }
      return selectedProductBaseChoice
    })
    setSelectedProductBaseChoices(productBaseChoices)
  }

  function updateSupplement(idSupplement, quantity) {
    let updatedSupplements = selectedSupplements.map(item => {
      if (item._id === idSupplement) {
        return { ...item, quantity: quantity }
      } else {
        return item
      }
    })
    setSelectedSupplements(updatedSupplements)
    if (itemToEdit) {
      setSupplements(defaultSupplements.map((ingred => ({ ...ingred, quantity: getIngredientQuantity(ingred._id, updatedSupplements) }))))
    }
  }

  function updateSupplementQuantity(supplement, value) {
    let quantity = (supplement.quantity || 1) + value
    supplement.quantity = quantity < 1 ? 1 : quantity
    quantity < 1
      ? addSupplement(supplement)
      : updateSupplement(supplement._id, quantity)
  }

  function addSupplement(supplement) {
    if (selectedSupplements.map(item => item.name).includes(supplement.name)) {
      setSelectedSupplements(selectedSupplements.filter(item => item._id !== supplement._id))
    } else {
      setSelectedSupplements([...selectedSupplements, supplement])
    }
  }

  const isAddedToCard = (supplement) => {
    return selectedSupplements.map(item => item.name).includes(supplement.name)
  }

  function getItemTotal() {
    let total = product.isProductWithWeight || isDiversCategory
      ? parseFloat(productWeightPrice)
      : (!withSizes ? product.prices[0]?.price : price)
    selectedSupplements.forEach(supplement => {
      let price = product.isProductWithWeight || isDiversCategory
        ? productWeightPrice
        : !withSizes ? supplement.prices[0]?.price : supplement.prices.filter(price => price.size?.name === size?.name)[0]?.price
      total += (price || 0) * (supplement.quantity ?? 1)
    })
    return total
  }

  function resetStates() {

    setSize("")
    setSelectedSupplements([])
    setPrice(0)
    setProductWeight("")
    setProductWeightPrice(0)
    setSelectedBaseChoices(baseChoices.map(item => ({ id: item._id, name: item.name, choice: item.choices[0].name, price: item.choices[0].price })))
    setSelectedProductBaseChoices(productBaseChoices.map(item => ({ id: item._id, name: item.name, choice: item.choices[0].name, price: item.choices[0].price })))
    setSelectedProduct(null)
  }

  return (
    <>
      <div
        style={{ pointerEvents: product?.isAvailable === false ? 'none' : null }}
        onClick={() => selectProduct(product)}
        className={`shadow-lg border border-gray-400 text-center px-2 py-4 cursor-pointer h-[70px] justify-center items-center flex flex-col ${selectedProduct && selectedProduct._id === product._id ? 'bg-primary text-white' : 'bg-white text-black'}`}>
        {product && <div style={{ opacity: product?.isAvailable === false ? 0.4 : 1 }} className="font-sans font-semibold text-[16px] leading-[22px]">{product.name}</div>}
        {!withSizes && !product?.isProductWithWeight && !isDiversCategory && <div style={{ opacity: product?.isAvailable === false ? 0.4 : 1 }} className="font-sans font-bold text-sm ">€{product.prices[0]?.price?.toFixed(2)} </div>}
        {withSizes && !product?.isProductWithWeight && !isDiversCategory && <div className="font-sans font-normal text-sm"><span style={{ opacity: product?.isAvailable === false ? 0.4 : 1 }}>{product.prices.length} Taille{product.prices.length > 1 ? "s" : ""}</span> {product?.isAvailable === false && <><br /><span style={{ opacity: 1, color: 'red' }} className="font-bold text-sm mt-2 text-primary">Indisponible</span></>}</div>}
        {/* {!withSizes && !product?.isProductWithWeight && product?.isAvailable === false && <span style={{ opacity: 1, color: 'red' }} className="font-bold text-lg mt-2 text-primary">Indisponible</span>} */}
      </div>

      <Dialog
        open={show}
        maxWidth='xl'
        fullWidth
        onClose={() => handleModalClose()}
        PaperProps={{
          style: {
            maxHeight: '95%',
          }
        }}
        className="modalWrapper">

        <DialogTitle style={{ paddingBottom: 0 }}>
          <div className="flex items-center justify-between border-b border-solid border-slate-200 rounded-t pb-2">
            <div className="flex">
              <h3 className="font-semibold text-[20px]"> {selectedProduct?.name} {withSizes && size && <span className='text-primary'> - {size?.name}</span>}</h3>
              {((!withSizes && !product?.isProductWithWeight) || (withSizes && size)) && <h3 className="text-2xl font-bold text-primary">&nbsp;€{size ? price : product.prices[0]?.price} </h3>}
            </div>
            <button
              className="p-1 bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={handleModalClose}>
              <FontAwesomeIcon icon={faClose} style={{ color: "#000", height: 30, width: 30 }} />
            </button>
          </div>
          <div className={'flex rounded'}>
            {!isDiversCategory && !product.isProductWithWeight && withSizes && product.prices.map((_size) => (
              <div
                onClick={() => setInfo(_size.size, _size.price)}
                className={'flex flex-col flex-1 bg-primary gap-1 cursor-pointer text-black border border-gray-700 text-center py-2 ' + (size?.name === _size.size.name ? "bg-primary text-white" : "bg-white text-black")}>
                <span className="text-[20px]">{_size.size.name}</span>
                <span className='text-center text-[20px] font-bold' style={{ marginTop: -10 }}>€{_size?.price}</span>
              </div>
            ))}

            {(product.isProductWithWeight || isDiversCategory) &&
              <div
                className={'flex flex-col flex-1 gap-1 cursor-pointer text-black border border-gray-700 text-center py-2 '}>
                <span className="text-[18px]">{isDiversCategory ? "Prix (€)" : "Poids (Kg)"}</span>
                <input
                  type="number"
                  min={0}
                  value={productWeight}
                  onChange={(e) => {
                    setProductWeight(e.target.value);
                    if (isDiversCategory) {
                      setProductWeightPrice(e.target.value)
                    } else {
                      setProductWeightPrice((product.pricePerKg * parseFloat(e.target.value)).toFixed(1))
                    }
                  }}
                  id="default-search"
                  className="p-1 py-3 px-5 text-xl text-black border-gray-300 w-[500px]"
                  style={{ borderWidth: 2, borderColor: 'black', alignSelf: 'center', borderStyle: 'dashed', borderRadius: 5, width: '30%' }}
                  placeholder={isDiversCategory ? "..." : "ex: 1.54"} required />
                {!isDiversCategory && <span className="text-[14px] pb-5" style={{ fontStyle: 'italic' }}>1 Kg = €{product.pricePerKg}</span>}
                {!isDiversCategory && <span className='text-center text-[18px] font-bold' style={{ marginTop: -10 }}>Prix : €{(product.pricePerKg * parseFloat(productWeight === "" ? 0 : productWeight)).toFixed(1)}</span>}
              </div>
            }
          </div>
          <div className='flex w-full justify-between mt-5'>
            {baseChoices?.length > 0 && baseChoices.map(item =>
              <h3 className={'flex-1 text-xl py-5 text-center font-bold ' + (item.name === selectedTab ? "bg-primary" : "bg-white")}
                style={{
                  border: "1px dashed #000",
                  color: item.name === selectedTab ? "#fff" : "#000"
                }}
                onClick={() => selectTab(item.name)}>{item.name}</h3>
            )}

            {productBaseChoices?.length > 0 && productBaseChoices.map(item =>
              <h3 className={'flex-1 text-xl py-5 text-center font-bold ' + (item.name === selectedTab ? "bg-primary" : "bg-white")}
                style={{
                  border: "1px dashed #000",
                  color: item.name === selectedTab ? "#fff" : "#000"
                }}
                onClick={() => selectTab(item.name)}>{item.name}</h3>
            )}

            {supplements?.length > 0 &&
              <h3 className={'flex-1 text-xl py-5 text-center font-bold ' + ("Choix de suppléments" === selectedTab ? "bg-primary" : "bg-white")}
                style={{
                  border: "1px dashed #000",
                  color: "Choix de suppléments" === selectedTab ? "#fff" : "#000"
                }}
                onClick={() => selectTab("Choix de suppléments")}>Choix de suppléments</h3>
            }
          </div>

        </DialogTitle>

        {(baseChoices?.length > 0 || productBaseChoices?.length > 0 || supplements?.length > 0) &&
          <DialogContent style={{ opacity: ((withSizes && size) || !withSizes || (product.isProductWithWeight && productWeightPrice > 0)) ? 1 : 0.4, pointerEvents: ((withSizes && size) || !withSizes || (product.isProductWithWeight && productWeightPrice > 0)) ? 'visible' : 'none' }}>
            <div className='flex flex-col sm:flex-row gap-4'>
              <div className='w-full mx-auto items-center'>
                <div className='flex flex-col gap-10'>
                  {baseChoices?.length > 0 && baseChoices.map(item => item.name === selectedTab &&
                    <div className="mt-5">
                      <div className='flex w-full justify-start items-center mb-5 px-2 py-2 bg-gray-200'>
                        <FontAwesomeIcon icon={faSquarePlus} style={{ color: "#000", height: 20, width: 20 }} />
                        <h3 className='text-base text-lg pt-1 font-bold mb-1 ml-2'>{item.name}</h3>
                        <FontAwesomeIcon icon={faSearch} style={{ color: "#000", height: 20, width: 20 }} className='ml-5' />
                        <input type="text" value={baseChoicesSearchText} onChange={(e) => setBaseChoicesSearchText(e.target.value)} id="default-search" className="p-1 py-5 text-xl text-black border-gray-300 w-[500px]" style={{ borderBottomWidth: 1, width: 500, marginLeft: 10, backgroundColor: 'transparent' }} placeholder="Chercher ... " required />
                        {baseChoicesSearchText !== "" &&
                          <FontAwesomeIcon
                            icon={faClose}
                            style={{ height: 20, width: 20 }}
                            onClick={() => setBaseChoicesSearchText("")}
                            className="px-5"
                          />
                        }
                      </div>
                      <div className='flex flex-wrap overflow-y-scroll'>
                        {item.choices.filter((choice) => choice.name?.includes(baseChoicesSearchText)).map(choice => {
                          return (
                            <div
                              className={`bg-${selectedBaseChoices.map(item => item.choice).includes(choice.name) ? 'primary text-white' : 'white text-black'} shadow-lg border w-40 text-center px-2 py-4 cursor-pointer h-32 justify-center items-center flex flex-col `}
                              onClick={() => addBaseChoice(item._id, choice)}
                            >
                              <div className='font-medium text-xl px-3 mb-2'>{choice.name}</div>
                              <span className='font-semibold text-xl'>{choice.price > 0 ? ("€" + choice.price) : ""}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {productBaseChoices?.length > 0 && productBaseChoices.map(item => item.name === selectedTab &&
                    <div className="mt-5">
                      <div className='flex w-full justify-start items-center mb-5 px-2 bg-gray-200'>
                        <FontAwesomeIcon icon={faSquarePlus} style={{ color: "#000", height: 20, width: 20 }} />
                        <h3 className='text-base text-lg pt-1 font-bold mb-1 ml-2'>{item.name}</h3>
                        <FontAwesomeIcon icon={faSearch} style={{ color: "#000", height: 20, width: 20 }} className='ml-5' />
                        <input type="text" value={productBaseChoicesSearchText} onChange={(e) => setProductBaseChoicesSearchText(e.target.value)} id="default-search" className="p-1 py-5 text-xl text-black border-gray-300 w-[500px]" style={{ borderBottomWidth: 1, width: 500, marginLeft: 10, backgroundColor: 'transparent' }} placeholder="Chercher ... " required />
                        {productBaseChoicesSearchText !== "" &&
                          <FontAwesomeIcon
                            icon={faClose}
                            style={{ height: 20, width: 20 }}
                            onClick={() => setProductBaseChoicesSearchText("")}
                            className="px-5"
                          />
                        }
                      </div>
                      <div className='flex flex-wrap overflow-y-scroll'>
                        {item.choices.filter((choice) => choice.name?.includes(productBaseChoicesSearchText)).map(choice => {
                          return (
                            <div
                              className={`bg-${selectedProductBaseChoices.map(item => item.choice).includes(choice.name) ? 'primary text-white' : 'white text-black'} shadow-lg border w-40 text-center px-2 py-4 cursor-pointer h-32 justify-center items-center flex flex-col `}
                              onClick={() => addProductBaseChoice(item._id, choice)}
                            >
                              <div className='font-medium text-xl mb-2'>{choice.name}</div>
                              <span className='font-semibold text-xl'>{choice.price > 0 ? (choice.price + " €") : ""}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {supplements?.length > 0 && "Choix de suppléments" === selectedTab &&
                    <div className="mt-5">
                      <div className='flex justify-start items-center mb-5 py-2 bg-gray-200'>

                        <div className='flex items-center'>
                          <FontAwesomeIcon icon={faSquarePlus} style={{ color: "#000", height: 20, width: 20 }} />
                          <h3 className='text-base text-lg pt-1 font-bold mb-1 ml-2'>Choix de suppléments</h3>
                        </div>

                        <div className='flex items-center'>
                          <FontAwesomeIcon icon={faSearch} style={{ color: "#000", height: 20, width: 20 }} className='ml-5' />
                          <input
                            type="text"
                            value={supplementsSearchText}
                            onChange={(e) => setSupplementsSearchText(e.target.value)}
                            id="default-search"
                            className="p-1 py-5 text-xl text-black border-gray-300 w-[500px]"
                            style={{ borderBottomWidth: 1, marginLeft: 10, backgroundColor: 'transparent' }}
                            placeholder={"Chercher ..."} required />
                          {supplementsSearchText !== "" &&
                            <FontAwesomeIcon
                              icon={faClose}
                              style={{ height: 20, width: 20 }}
                              onClick={() => setSupplementsSearchText("")}
                              className="px-5"
                            />
                          }
                        </div>

                      </div>

                      <div className='flex flex-wrap '>
                        {supplements.filter((supplement) => supplement.name?.includes(supplementsSearchText)).map((item, index) => {
                          const isSelected = isAddedToCard(item)
                          return (
                            <div
                              className={`bg-${isSelected ? 'primary text-white' : 'white text-black'} shadow-lg border w-60 text-center px-2 py-4 cursor-pointer h-32 justify-center items-center flex flex-col `}
                              onClick={() => isAddedToCard(item) ? null : addSupplement(item)}>

                              <div className="text-lg font-normal">{getSupplement(item).name}</div>
                              <span className='font-semibold text-lg m-2'>{getSupplement(item).price}</span>

                              {isSelected && (
                                <div className='flex gap-1 items-center '>
                                  <button
                                    onClick={() => updateSupplementQuantity(item, -1)}
                                    className='rounded-full h-8 w-8 leading-3 pb-0.5 border-2 border-black bg-yellow-500 text-xl text-white text-center cursor-pointer'
                                  >
                                    -
                                  </button>
                                  <span className='font-medium text-xl px-3'>{item?.quantity || 1}</span>
                                  <button
                                    onClick={() => updateSupplementQuantity(item, +1)}
                                    className='rounded-full h-8 w-8 border-2 border-black bg-yellow-500 pb-0.5 leading-3 text-xl text-white text-center cursor-pointer'
                                  >
                                    +
                                  </button>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </DialogContent>
        }
        <div className="flex border-t w-full">
          <button
            className="bg-black flex gap-3 w-full items-center justify-center text-white font-bold uppercase text-md py-6"
            type="button"
            onClick={() => handleModalClose()}>
            <FontAwesomeIcon icon={faClose} style={{ color: "#fff", height: 25, width: 25 }} />
            FERMER
          </button>
          <button
            className="bg-primary flex gap-3 w-full items-center justify-center text-white font-medium uppercase text-md py-6"
            type="button"
            style={{ opacity: ((withSizes && size) || !withSizes || (product.isProductWithWeight && productWeightPrice > 0)) ? 1 : 0.4 }}
            disabled={!((withSizes && size) || !withSizes || (product.isProductWithWeight && productWeightPrice > 0))}
            onClick={() => saveToCart()}>
            <FontAwesomeIcon icon={faCartPlus} style={{ color: "#fff", height: 25, width: 25 }} />
            {itemToEdit !== null ? ' Modifier' : 'Ajouter au panier'} - <b>€{getItemTotal()}</b>
          </button>
        </div>
      </Dialog>
    </>
  )
}



function mapStateToProps(state) {
  return {
    _supplements: state.category.supplements,
    scaleWeight: state.global.scaleWeight

  }
}

export default connect(mapStateToProps)(ProductInfo);