import { React, useEffect, useState, createRef } from 'react'
import QRCode from 'qrcode'
import './style.css';
import logo from '../../assets/images/logo2.png'
import { useScreenshot } from 'use-react-screenshot';
import { connect, useDispatch } from 'react-redux';
import { setOrderToPrint } from '../../store/actions/OrderActions';
import moment from 'moment';
import { TOAST } from '../../utils/Helpers';

const OrderReceipt = ({ printerConnexionMode, printers, paperSize, copies, margin, orderToPrint, hideCartModal }) => {

    const [qrcodeUri, setQrCodeUri] = useState("")
    const [image, takeScreenShot] = useScreenshot();
    const ref = createRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        printOrderReceipt()
    }, [])

    async function printOrderReceipt() {

        let isUsbPort = printerConnexionMode === "USB"
        let _copies = copies

        if (isUsbPort) {
            let imagedata = await takeScreenShot(ref.current)
            let usbData = (imagedata + "#" + _copies)
            window.location.href = "#print-order-receipt#" + usbData
            dispatch(setOrderToPrint(null))
        } else {
            if (printers.length === 0) {
                TOAST("Veuillez ajouter une imprimante", "error")
                return
            }
            printers.map(async (printer) => {
                if (printer.ipAddress === "") {
                    TOAST("Veuillez ajouter l'adresse ip de " + printer.name, "error")
                    return;
                }
                let imagedata = await takeScreenShot(ref.current)
                let ethernetData = (imagedata + "#" + _copies + "#" + printer.ipAddress)
                window.location.href = "#print-order-receipt#" + ethernetData + "#" + (ref?.current?.offsetHeight)
                dispatch(setOrderToPrint(null))
            })
        }
    }

    function getSupplementQuantity(orderItem, supplementId) {
        let supplementQts = orderItem.ingredientsQts.filter(item => item._id === supplementId)
        return supplementQts.length > 0 && supplementQts[0].quantity > 1 ? (supplementQts[0].quantity + "x ") : ""
    }

    function getCategoryName(item) {
        let isOneItemWithWeight = false
        item.items.forEach(product => {
            if (product.product?.isProductWithWeight === true) {
                isOneItemWithWeight = true
            }
        });
        return isOneItemWithWeight ? (item.category.indexOf(" - ") === -1 ? item.category : item.category.split(" - ")[0]) : item.category
    }

    if (printerConnexionMode === "USB") {
        return (
            <div className='flex flex-col pb-7 items-center' style={{ backgroundColor: 'white', paddingRight: margin, width: paperSize === 58 ? 390 : 570, position: 'absolute', bottom: 5000 }} ref={ref}>
                <p className='text-[55px] font-bold' style={{ fontWeight: 'bold', color: 'black' }}>{orderToPrint?.shop?.name}</p>
                <p className='text-[40px]' style={{ textAlign: 'center', fontWeight: 'bold', color: 'black' }}>{orderToPrint?.shop?.address}, {orderToPrint?.shop?.postalCode}</p>
                <p className='text-[40px]' style={{ fontWeight: 'bold', color: 'black' }}>Tél: {orderToPrint?.shop?.mobile}</p>
                <p className='text-[37px] font-medium mt-3' style={{ fontWeight: 'bold', color: 'black' }}>{moment(orderToPrint?.createdAt).format("DD MMM YYYY HH:mm")}</p>

                {orderToPrint?.items?.map((item, index) => <>
                    <p className='text-[40px] font-semibold w-full text-left px-5 mt-5' style={{ fontWeight: 'bold', color: 'black' }}>{getCategoryName(item)}</p>
                    {item.items.map((product, index) => (
                        <>
                            <div className='flex mt-1 justify-between px-8 w-full'>
                                <p className='text-[37px] font-medium' style={{ fontWeight: 'bold', color: 'black' }}>{product.quantity} {(!product.product?.isProductWithWeight ? product.product?.name : (product.product?.name + " - " + product.size)) || product.menu?.choices[0]?.name}</p>
                                <p className='text-[37px] font-medium' style={{ fontWeight: 'bold', color: 'black' }}>{product.price === 0 ? "Gratuit" : "€" + product.price}</p>
                            </div>

                            {product.baseChoices?.map((baseChoice, index) =>
                                <p className='text-[35px] px-12 w-full text-left' style={{ fontWeight: 'bold', color: 'black' }}>+ {baseChoice.choice}</p>
                            )}

                            {product.productBaseChoices?.map((baseChoice, index) =>
                                <p className='text-[35px] px-12 w-full text-left' style={{ fontWeight: 'bold', color: 'black' }}>+ {baseChoice.choice}</p>
                            )}

                            {product.ingredients?.map((ingredient, index) =>
                                <p className='text-[35px] px-12 w-full text-left' style={{ fontWeight: 'bold', color: 'black' }}>+ {getSupplementQuantity(product, ingredient._id)}{ingredient.name}</p>
                            )}
                        </>
                    ))}
                </>
                )}

                <div className='flex mt-6 justify-between px-5 w-full'>
                    <p className='text-[37px]' style={{ fontWeight: 'bold', color: 'black' }}>Sous total</p>
                    <p className='text-[37px]' style={{ fontWeight: 'bold', color: 'black' }}>€{parseFloat(orderToPrint?.subtotal?.toFixed(2))}</p>
                </div>


                {orderToPrint?.comment !== "" && orderToPrint?.comment !== "0" && orderToPrint?.comment !== "0.00" &&
                    <div className='flex mt-2 justify-between px-8 w-full'>
                        <p className='text-[37px]' style={{ fontWeight: 'bold', color: 'black' }}>Réduction</p>
                        <p className='text-[37px]' style={{ fontWeight: 'bold', color: 'black' }}>-€{parseFloat(orderToPrint?.comment).toFixed(2)}</p>
                    </div>
                }

                <div className={'flex mt-2 justify-between px-5 w-full' + (orderToPrint?.isKitchenInPlaceReceipt !== true ? "" : " mb-3")}>
                    <p className='text-[40px] font-semibold' style={{ fontWeight: 'bold', color: 'black' }}>TOTAL</p>
                    <p className='text-[40px] font-semibold' style={{ fontWeight: 'bold', color: 'black' }}>€{parseFloat(orderToPrint?.total?.toFixed(2))}</p>
                </div>

                <div className='flex mt-7'>
                    {/* <img alt="" src={logo} height={25} width={25} style={{ objectFit: 'contain' }} /> */}
                    <p className='text-[37px] font-normal ml-3' style={{ fontWeight: 'bold', color: 'black' }}>Powerd by B-TIP</p>
                </div>

            </div>
        )
    }

    return (
        <div className='flex flex-col pb-7 items-center' style={{ backgroundColor: 'white', paddingRight: margin, width: paperSize === 58 ? 390 : 570, position: 'absolute', bottom: 5000 }} ref={ref}>
            <p className='text-[60px] font-bold'>{orderToPrint?.shop?.name}</p>
            <p className='text-[40px]'>{orderToPrint?.shop?.address}, {orderToPrint?.shop?.postalCode}</p>
            <p className='text-[40px]'>Tél: {orderToPrint?.shop?.mobile}</p>
            <p className='text-[40px] font-medium mt-3'>{moment(orderToPrint?.createdAt).format("DD MMM YYYY HH:mm")}</p>

            {orderToPrint?.items?.map((item, index) => <>
                <p className='text-[40px] font-semibold w-full text-left px-5 mt-5 '>{getCategoryName(item)}</p>
                {item.items.map((product, index) => (
                    <>
                        <div className='flex mt-1 justify-between px-8 w-full'>
                            <p className='text-[38px] font-medium'>{product.quantity} {(!product.product?.isProductWithWeight ? product.product?.name : (product.product?.name + " - " + product.size)) || product.menu?.choices[0]?.name}</p>
                            <p className='text-[38px] font-medium'>{product.price === 0 ? "Gratuit" : "€" + product.price}</p>
                        </div>

                        {product.baseChoices?.map((baseChoice, index) =>
                            <p className='text-[38px] px-12 w-full text-left'>+ {baseChoice.choice}</p>
                        )}

                        {product.productBaseChoices?.map((baseChoice, index) =>
                            <p className='text-[38px] px-12 w-full text-left'>+ {baseChoice.choice}</p>
                        )}

                        {product.ingredients?.map((ingredient, index) =>
                            <p className='text-[38px] px-12 w-full text-left'>+ {getSupplementQuantity(product, ingredient._id)}{ingredient.name}</p>
                        )}

                        {product.description && product.description !== "" && <p className='text-[36px] px-12 w-full mt-1 text-left' style={{ fontStyle: 'italic' }}>{product.description}</p>}
                    </>
                ))}
            </>
            )}

            <div className='flex mt-6 justify-between px-8 w-full'>
                <p className='text-[36px]'>Sous total</p>
                <p className='text-[36px]'>€{parseFloat(orderToPrint?.subtotal?.toFixed(2))}</p>
            </div>

            {orderToPrint?.comment !== "" && orderToPrint?.comment !== "0" && orderToPrint?.comment !== "0.00" &&
                <div className='flex mt-2 justify-between px-8 w-full'>
                    <p className='text-[36px]'>Réduction</p>
                    <p className='text-[36px]'>-€{parseFloat(orderToPrint?.comment).toFixed(2)}</p>
                </div>
            }

            <div className={'flex mt-2 justify-between px-8 w-full' + (orderToPrint?.isKitchenInPlaceReceipt !== true ? "" : " mb-3")}>
                <p className='text-[40px] font-semibold'>TOTAL</p>
                <p className='text-[40px] font-semibold'>€{parseFloat(orderToPrint?.total?.toFixed(2))}</p>
            </div>

            <div className='flex my-3'>
                {/* <img alt="" src={logo} height={25} width={25} style={{ objectFit: 'contain' }} /> */}
                <p className='text-[37px] font-normal ml-3'>Powerd by B-TIP</p>
            </div>
            <div style={{ height: 50}} />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        receiptVisibleInfos: state.parameters.printerParameters.receiptVisibleInfos,
        printerConnexionMode: state.parameters.printerParameters.printerConnexionMode,
        printerEthernetIpAddress: state.parameters.printerParameters.printerEthernetIpAddress,
        printers: state.parameters.printerParameters.printers,
        paperSize: state.parameters.printerParameters.receiptPaperSize,
        copies: state.parameters.printerParameters.copies,
        margin: state.parameters.printerParameters.margin,
        orderToPrint: state.order.orderToPrint
    }
}

export default connect(mapStateToProps)(OrderReceipt);