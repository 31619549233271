import { React, useEffect, useState, createRef } from 'react'
import QRCode from 'qrcode'
import './style.css';
import logo from '../../assets/images/logo2.png'
import { useScreenshot } from 'use-react-screenshot';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { TOAST } from '../../utils/Helpers';
import Barcode from 'react-barcode';

const ProductBarCodeReceipt = ({ data, isPrintBarCode, printerConnexionMode, printers, paperSize, setPrintBarCode }) => {

    useEffect(() => {
        getBarCode()
        if (isPrintBarCode) {
            printBarCode()
        }
    }, [isPrintBarCode])

    useEffect(() => {
        getBarCode()
    }, [data.items])

    const ref = createRef(null)
    const [image, takeScreenShot] = useScreenshot();
    const [barCode, setBarCode] = useState("");

    async function printBarCode() {

        let isUsbPort = printerConnexionMode === "USB"
        let _copies = 1

        if (isUsbPort) {
            let imagedata = await takeScreenShot(ref.current)
            let usbData = (imagedata + "#" + _copies)
            window.location.href = "#print-order-receipt#" + usbData
            setPrintBarCode(false)
        } else {
            if (printers.length === 0) {
                TOAST("Veuillez ajouter une imprimante", "error")
                return
            }
            printers.map(async (printer) => {
                if (printer.ipAddress === "") {
                    TOAST("Veuillez ajouter l'adresse ip de " + printer.name, "error")
                    return;
                }
                let imagedata = await takeScreenShot(ref.current)
                let ethernetData = (imagedata + "#" + _copies + "#" + printer.ipAddress)
                window.location.href = "#print-order-receipt#" + ethernetData + "#" + ref.current.offsetHeight
                setPrintBarCode(false)
            })
        }
    }

    async function getBarCode() {

        let barcode = ""
        data?.items?.forEach(element => {
            barcode += element.product.productBarCodeNumber + "-" + element.price + ";"
        });
        console.log("barcode", barcode);
        setBarCode(barcode)
    }

    function prepareBarCode() {

        const input = barCode;

        // Step 1: Split the string into individual items
        const items = input.split(";").filter(item => item !== "");

        // Step 2: Create an array of three items in each index
        const result = [];
        for (let i = 0; i < items.length; i += 3) {
            const group = items.slice(i, i + 3).join(";") + ";"; // Join group with ";" and append a trailing semicolon
            result.push(group);
        }

        return result
    }

    if (printerConnexionMode === "USB") {
        return <div className='flex flex-col items-center' style={{ backgroundColor: 'white', width: paperSize === 58 ? 390 : 570, position: 'fixed', top: 400, left: 0 }} ref={ref}>
            <p className='text-[35px] font-bold my-5' style={{ fontWeight: 'bold', color: 'black' }}>TOTAL</p>
            <p className='text-[35px] font-bold mt-5 mb-10' style={{ fontWeight: 'bold', color: 'black' }}>€{data?.total || "0"}</p>

            {prepareBarCode().map((item) =>
                <div>
                    <div style={{ height: 100 }} />
                    <Barcode value={item} width={2} />
                </div>
            )}
            <div style={{ height: 30 }} />
        </div>
    }

    return (
        <div className='flex flex-col items-center' style={{ backgroundColor: 'white', width: paperSize === 58 ? 390 : 570, position: 'fixed', top: 400, left: 0 }} ref={ref}>
            <p className='text-[30px] font-medium'>TOTAL</p>
            <p className='text-[30px] font-medium mt-5 mb-10'>€{data?.total || "0"}</p>
            {prepareBarCode().map((item) =>
                <div>
                    <div style={{ height: 100 }} />
                    <Barcode value={item} width={2} />
                </div>
            )}
            <div style={{ height: 30 }} />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        receiptVisibleInfos: state.parameters.printerParameters.receiptVisibleInfos,
        printerConnexionMode: state.parameters.printerParameters.printerConnexionMode,
        printerEthernetIpAddress: state.parameters.printerParameters.printerEthernetIpAddress,
        paperSize: state.parameters.printerParameters.receiptPaperSize,
        printers: state.parameters.printerParameters.printers,
        copies: state.parameters.printerParameters.copies,
        orderToPrint: state.order.orderToPrint
    }
}

export default connect(mapStateToProps)(ProductBarCodeReceipt);